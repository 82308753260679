<template>
  <div class="container">
    <h1 class="title">
      {{ $t('views.membershipCreate.title') }}
    </h1>

    <v-form
      :onSubmit="sendForm"
      :onSubmitSuccess="onSubmitSuccess"
      :onSubmitError="onSubmitError"
      :process-form="changeDateFormat"
      ref="personCreateForm"
    >
      <template v-slot="{ form }">
        <message-success v-if="showSuccess" :detail="$t('signUpForm.successMsg')" />
        <message-error v-if="form.errors.length > 0" :detail="Object.values(form.errors).flat()" />
        <message-error v-if="form.errors.__root__" :detail="form.errors.__root__" />
        <div class="column is-tablet">
          <div class="card">
            <div class="card-content">
              <div class="media">
                <div class="media-left">
                  <b-icon icon="information-outline" size="is-large" type="is-azul-imfd" />
                </div>

                <div class="media-content">
                  {{ $t('personCreate.infoMessage') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-tablet">
          <div class="column">
            <b-field
              :label="$t('personCreate.email')"
              id="email-input"
              :message="form.errors.email"
              :type="form.types.email"
            >
              <b-input v-model="form.values.email" aria-label="email-address" />
            </b-field>
          </div>
          <div class="column">
            <b-field
              :label="$t('signUpForm.idNumberLabel')"
              id="id-number-input"
              :message="form.errors.id_number"
              :type="form.types.id_number"
            >
              <b-input v-model="form.values.id_number" autocomplete="off" aria-label="id-number" />
            </b-field>
          </div>
        </div>
        <div class="columns is-tablet">
          <div class="column">
            <b-field
              :label="$t('signUpForm.firstNameLabel')"
              id="first-name-input"
              :message="form.errors.first_name"
              :type="form.types.first_name"
            >
              <b-input v-model="form.values.first_name" aria-label="first-name" />
            </b-field>
          </div>
          <div class="column">
            <b-field
              :label="$t('signUpForm.lastNameLabel')"
              id="last-name-input"
              :message="form.errors.last_name"
              :type="form.types.last_name"
            >
              <b-input v-model="form.values.last_name" aria-label="last-name" />
            </b-field>
          </div>
        </div>

        <div class="columns is-tablet">
          <div class="column">
            <b-field
              :label="$t('signUpForm.nationalityLabel')"
              id="nationality-select"
              :message="form.errors.nationality"
              :type="form.types.nationality"
            >
              <country-selector v-model="form.values.nationality" />
            </b-field>
          </div>
          <div class="column">
            <b-field
              :label="$t('personCreate.region')"
              id="region-input"
              :message="form.errors.region"
              :type="form.types.region"
            >
              <b-input v-model="form.values.region" aria-label="region" />
            </b-field>
          </div>
        </div>

        <div class="columns is-tablet">
          <div class="column">
            <b-field
              :label="$t('membershipCreateForm.membershipTypeLabel')"
              :message="form.errors.membership_type_id"
              :type="form.types.membership_type_id"
            >
              <membership-type-selector
                ref="membershipType"
                v-model="form.values.membership_type_id"
              />
            </b-field>
          </div>
          <div class="column">
            <b-field
              :label="$t('signUpForm.degree')"
              id="degree-select"
              :message="form.errors.degree_id"
              :type="form.types.degree_id"
            >
              <degree-selector v-model="form.values.degree_id" />
            </b-field>
          </div>
        </div>

        <div class="columns is-tablet">
          <div class="column">
            <b-field
              :label="$t('personCreate.startDate')"
              :message="form.errors.start_date"
              :type="form.types.start_date"
            >
              <b-datepicker
                v-model="form.values.start_date"
                :placeholder="$t('form.clickToSelect')"
                :value="form.values.start_date ? dateWithoutOffset(form.values.start_date) : null"
                @input="
                  (value) => {
                    form.values.start_date = value
                  }
                "
                aria-label="start-date"
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="column">
            <b-field
              :label="$t('personCreate.endDate')"
              :message="form.errors.end_date"
              :type="form.types.end_date"
            >
              <b-datepicker
                v-model="form.values.end_date"
                :placeholder="$t('form.clickToSelect')"
                :value="form.values.end_date ? dateWithoutOffset(form.values.end_date) : null"
                @input="
                  (value) => {
                    form.values.end_date = value
                  }
                "
                aria-label="end-date"
              >
              </b-datepicker>
            </b-field>
          </div>
        </div>
        <div class="is-flex is-justify-content-right">
          <div>
            <b-button
              class="mt-4"
              native-type="submit"
              type="is-azul-imfd"
              expanded
              id="send-button"
              >{{ $t('personCreate.save') }}</b-button
            >
          </div>
        </div>
      </template>
    </v-form>
  </div>
</template>

<script>
import CountrySelector from '@/components/CountrySelector'
import DegreeSelector from '@/components/DegreeSelector'
import VForm from '@/components/VForm'
import MembershipTypeSelector from '@/components/MembershipTypeSelector'
import { createPerson } from '@/requests/people'
import { dateWithoutOffset, toBackendFormat } from '@/utils/date-utils'

export default {
  name: 'PersonCreate',
  components: {
    CountrySelector,
    DegreeSelector,
    VForm,
    MembershipTypeSelector
  },

  methods: {
    dateWithoutOffset,
    async sendForm(form) {
      this.loading = this.$buefy.loading.open({
        container: this.$refs.personCreateForm.$el
      })

      await createPerson(form)
    },
    onSubmitSuccess() {
      this.loading.close()
      this.$buefy.toast.open({
        message: this.$t('personCreate.successMessage'),
        type: 'is-success'
      })

      this.$router.push({ name: 'controlPanel' })
    },
    onSubmitError(error) {
      this.loading.close()
      this.$buefy.toast.open({
        message: error.message,
        type: 'is-danger'
      })
    },
    changeDateFormat(form) {
      form.start_date = form.start_date ? toBackendFormat(new Date(form.start_date)) : null
      form.end_date = form.end_date ? toBackendFormat(new Date(form.end_date)) : null
      return form
    }
  },
  data() {
    return {
      showSuccess: false,
      loading: null
    }
  }
}
</script>
